import React from 'react'
import './AdminPage.css'
import { AdminpageHeader } from './AdminpageHeader';

export const AdminPage = () => {
  return (
    <>
    {/* <div className="container"> */}
    <AdminpageHeader />
    {/* </div> */}
    </>)
}

