import santec from './santec.png';
import santecBlue from './santec-blue.png';
import valtec from './valtec.png';
import sanTherm from './san-therm.png';
import nuovo from './nuovo.png';

export const images = 
{

    "nuovo":nuovo,
    "santec":santec,
    "santec-blue":santecBlue,
    "valtec":valtec,
    "san-therm":sanTherm,

};